import React from 'react';
import ProductList from './ProductList';

interface MultiChoiceItem {
    quantity: string;
    product_code: string;
}

interface ChoiceType {
    type: string;
    value: MultiChoiceItem[];
}

interface DataStructure {
    good: ChoiceType[];
    better: ChoiceType[];
    best: { type: string; value: string; edit_link: string }[];
}

function areGoodAndBetterSame(data: DataStructure): boolean {
    const goodItems = data.good[0].value;
    const betterItems = data.better[0].value;

    // Check if both item lists are arrays and return false if not
    if (!Array.isArray(goodItems) || !Array.isArray(betterItems)) {
        return false;
    }

    // Sort the arrays by product_code to ensure consistent comparison
    goodItems.sort((a, b) => a.product_code.localeCompare(b.product_code));
    betterItems.sort((a, b) => a.product_code.localeCompare(b.product_code));

    // Check if the lengths are the same
    if (goodItems.length !== betterItems.length) {
        return false;
    }

    // Compare each item in the sorted arrays
    for (let i = 0; i < goodItems.length; i++) {
        if (
            goodItems[i].product_code !== betterItems[i].product_code ||
            goodItems[i].quantity !== betterItems[i].quantity
        ) {
            return false;
        }
    }

    return true;
}


type Props = {
  roomCode: string;
  spaceData: any;
  selectedRating: string;
  setRating: (roomCode: string, rating: string) => void;
  getSelectedProductCode: (roomCode: string, rating: string, idx: number) => string | null;
  setRoomSelection: (roomCode: string, rating: string, productCode: string, index: number, quantity: number, reject: boolean) => void;
  productData: any;
  getPrice: (productCode: string) => { price: number, discount: number, currency: string };
};

const RoomRecommendations: React.FC<Props> = ({
  roomCode,
  spaceData,
  selectedRating,
  setRating,
  getSelectedProductCode,
  setRoomSelection,
  productData,
  getPrice
}) => {
  let articles = spaceData.articles
  if (!articles && spaceData.tabs) {
    articles = spaceData.tabs[roomCode].articles
  }
  if (!articles) {
    return <div>{window.gettext("No recommendations available for this room.")}</div>;
  }
  return (
    <div className="">
      {!areGoodAndBetterSame(articles) && <ul className="flex rounded-xl align-middle bg-[#F5F7F9] text-[#1D1F22] mb-4">
        <li
          className={`w-full h-full text-center ${selectedRating === 'good' ? 'bg-gray-800 text-white rounded-l-[12px]' : 'rounded-l-[12px] bg-white print:hidden'}`}>
          <button type="button" className="w-full h-full p-[8px]" onClick={() => setRating(roomCode, 'good')}>
            {window.gettext("Good")}
          </button>
        </li>
        <li
          className={`w-full h-full text-center ${selectedRating === 'better' ? 'bg-gray-800 text-white rounded-r-[12px]' : 'rounded-r-[12px] bg-white print:hidden'}`}>
          <button type="button" className="w-full h-full p-[8px]" onClick={() => setRating(roomCode, 'better')}>
            {window.gettext("Better")}
          </button>
        </li>
      </ul>}
      <ProductList
        roomCode={roomCode}
        spaceData={spaceData}
        suggestedArticles={articles}
        rating={selectedRating}
        getSelectedProductCode={getSelectedProductCode}
        setRoomSelection={setRoomSelection}
        productData={productData}
        getPrice={getPrice}
      />
      </div>
  );
};

export default RoomRecommendations;

// Functional Component: Currency Formatted for Norwegians

import React from 'react';


const FxCurrencyFormat = (amount) => {
  return (
    <span className="">
      {formatCurrency(amount)}
    </span>
  );
};

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('nb-NO', { style: 'decimal', minimumFractionDigits: 2 }).format(amount);
};

export default FxCurrencyFormat;

import React, {useState, useEffect} from 'react';
import ProductCard from './ProductCard';

type Props = {
  roomCode: string;
  spaceData: any;
  suggestedArticles: any;
  rating: string;
  getSelectedProductCode: (roomCode: string, rating: string, idx: number) => string | null;
  setRoomSelection: (roomCode: string, rating: string, productCode: string, index: number, quantity: number, reject: boolean) => void;
  productData: any;
  getPrice: (productCode: string) => { price: number, discount: number, currency: string };
};

const ProductList: React.FC<Props> = ({
                                        roomCode,
                                        spaceData,
                                        suggestedArticles,
                                        rating,
                                        getSelectedProductCode,
                                        setRoomSelection,
                                        productData,
                                        getPrice
                                      }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(false); // Start fade out
    const timeoutId = setTimeout(() => setVisible(true), 60); // Wait for fade-out to complete before showing new content

    return () => clearTimeout(timeoutId);
  }, [roomCode, rating]);

  const articles = suggestedArticles[rating] || [];

  return (
    <div className={`grid sm:flex gap-[40px] transition-opacity duration-300 ${visible ? 'opacity-100' : 'opacity-0'}`}>
      <div className="w-full sm:print:w-full">
        {articles.map((article, idx) => {
          return (
            <div key={idx} className="pb-4 break-inside-avoid-page">
              <ProductCard
                key={`sngl-${idx}`}
                productData={productData}
                spaceData={spaceData}
                suggestedArticles={suggestedArticles}
                roomCode={roomCode}
                rating={rating}
                article={article}
                setRoomSelection={setRoomSelection}
                itemIndex={idx}
                getSelectedProductCode={getSelectedProductCode}
                getPrice={getPrice}
              />
            </div>
          );
        })}
      </div>
      {/* Include ThinkAboutIt component if needed */}
    </div>
  );
};

export default ProductList;

import React from 'react';
import Spinner from "../common/spinner";
import FxCurrencyFormat from './currencyformat';

type Props = {
  selectedData: any;
  products: any;
  spaces: any;
  price_list: { [key: string]: { price: number, discount: number } };
  closeModal: () => void;
  handleProceedToCart: () => void;
  populatingCart: boolean;
  populatingCartError: string;
};

const CartModal: React.FC<Props> = ({selectedData, products, spaces, price_list, closeModal, handleProceedToCart, populatingCart, populatingCartError}) => {
  const getPrice = (productCode: string) => {
    const priceData = price_list[productCode];
    if (priceData) {
      return priceData;
    }
    // Generate random price if not available in price_list
    return {price: 0, discount: 0};
  };

  const groupedProducts = Object.entries(selectedData).flatMap(([roomCode, roomData]: any) => {
    return Object.entries(roomData.selectedProducts).map(([index, product]: any) => {
      if (product) {
        const productData = products.find((p: any) => p.product_number === product.productCode);
        const roomType = roomCode.replace(/[0-9]/g, '');
        const roomLabel = spaces[roomType].label;
        const roomNumber = roomCode.match(/[0-9]+$/) ? parseInt(roomCode.match(/[0-9]+$/)[0], 10) + 1 : 1; // Extract room number

        return {
          productCode: product.productCode,
          productName: productData.name,
          quantity: parseInt(product.quantity, 10), // Ensure quantity is treated as a number
          roomLabel: `${roomLabel} ${roomNumber}`,
          imageUrl: productData.product_images_urls[0]?.small_thumbnail_url,
          price: getPrice(product.productCode)
        };
      }
    });
  }).reduce((acc: any, product) => {
    const existingProduct = acc.find((p: any) => p.productCode === product?.productCode);
    if (existingProduct) {
      existingProduct.totalQuantity += product.quantity;
      existingProduct.rooms.push({roomLabel: product.roomLabel, quantity: product.quantity});
    } else if (product) {
      acc.push({
        productCode: product.productCode,
        productName: product.productName,
        totalQuantity: product.quantity,
        rooms: [{roomLabel: product.roomLabel, quantity: product.quantity}],
        imageUrl: product.imageUrl,
        price: product.price
      });
    }
    return acc;
  }, []);

  const grandTotal = groupedProducts.reduce((total: number, product: any) => {
    return total + (product.price.price * (1 - product.price.discount / 100) * product.totalQuantity);
  }, 0);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-2" onClick={closeModal}>
      <div
        className="bg-white rounded-lg px-3 py-4 mx-0 my-4 overflow-y-auto max-h-[calc(100vh-4rem)] w-full sm:max-w-lg"
        
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl font-semibold mb-4">{window.gettext('Selected Products')}</h2>
        <ul>
          {groupedProducts.map((product: any, index: number) => (
            <li key={index} className="mb-4">

              <div className="flex items-start">
                <img src={product.imageUrl} alt={product.productName} className="object-top w-16 h-16 mr-4"/>
                <div>
                  <h3 className="font-semibold">{product.productName}</h3>
                  {/*                   
                  <p>
                    <span className="mr-1">{product.totalQuantity} stk a&#768;</span>
                    {FxCurrencyFormat(product.price.price * (1 - product.price.discount / 100))}
                    {product.price.discount > 0 && (
                      <span className="line-through ml-2">{product.price.currency} {FxCurrencyFormat(product.price.price)}</span>
                    )}
                  </p> 
                  */}
                  <p>
                    {/* {window.gettext('Total')}:  */}
                    {FxCurrencyFormat(product.totalQuantity * product.price.price * (1 - product.price.discount / 100))}
                  </p>
                  <ul className="inline-flex flex-wrap space-x-1">
                    {product.rooms.map((room: any, roomIndex: number) => (
                      <li key={roomIndex} className="text-sm">
                        {room.quantity} for {room.roomLabel}{roomIndex < product.rooms.length - 1 ? ', ' : '.'}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className=" font-semibold mt-4">
          {window.gettext('Grand Total')}: {FxCurrencyFormat(grandTotal)}
        </div>
        <button onClick={handleProceedToCart} disabled={populatingCart} className="mt-4 w-full bg-fxblue hover:bg-gray-300 px-4 py-2 rounded-lg text-white">
          {populatingCart ? <Spinner size="md"/> : window.gettext('Proceed to Megaflis cart')}
        </button>
        {populatingCartError && <p className="text-red-500 text-sm mt-2">{populatingCartError}</p>}
        <p className="mt-5">
        {window.gettext('Want to change something? Close and redo selections!')}</p>
        <button onClick={closeModal} className="w-full mt-4 bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-lg">
          {window.gettext('Close')}
        </button>
      </div>
    </div>
  );
};

export default CartModal;
